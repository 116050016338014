.btn {
  display: inline-block;
  font-family: Raleway, sans-serif;
  font-size: 26px;
  color: var(--blueText);
  padding: 32px;
  position: relative;
  letter-spacing: 1px;

  &__circle,
  &__text,
  &__white-circle {
    position: absolute;
  }

  &__circle {
    top: 0;
    left: 0;
    height: 100%;
    border-radius: 100%;
    width: 100%;
    box-shadow: 0 0 1px 2px #3fccf7;
    transition: 0.3s linear;
  }

  &__white-circle {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 56px;
    height: 56px;
    border-radius: 100%;
    background: #3fccf7;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    box-shadow: 0 0 1px 2px white;

    svg {
      width: 24px;
      height: 24px;
      margin: auto;
      color: var(--blueText);
    }
  }

  &__text {
    top: 50%;
    transform: translateY(-50%);
    white-space: nowrap;
    z-index: 2;
    padding: 24px 8px;
    transition: 0.3s linear;
  }

  &:hover {
    .btn__circle {
      transform: scale(0);
    }

    .btn__white-circle {
      transform: translate(-50%, -50%) scale(1);
    }

    .btn__text {
      transform: translate(40px, -50%);
    }
  }
}

.content {
  background: black;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.light {
  color: white;
}

.circle-white {
  box-shadow: 0 0 1px 2px white;
}

@media (max-width: 1000px) {
  .btn {
    font-size: 20px;
  }
}
