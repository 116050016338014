.features-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url(../../assets/images/bg-wave.png);
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Raleway, sans-serif;
  overflow: hidden;
  font-size: 20px;
}

.features-content {
  width: 86%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.title {
  margin-bottom: 30px;
}

.title span {
  font-size: 2.25em;
  font-weight: 400;
  line-height: 60.93px;
  color: white;
}

.title span:nth-child(4) {
  font-weight: 600;
}

.subtitle {
  font-size: 1.3em;
  font-weight: 26px;
  line-height: 35.2px;
  color: white;
  margin-bottom: 70px;
}

.frames {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 60px;
}

.frame-1,
.frame-2,
.frame-3,
.frame-4 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  height: 250px;
  background: radial-gradient(
        31.95% 71.19% at 22.35% 32.15%,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(
      101.19deg,
      rgba(255, 255, 255, 0.3) 0.88%,
      rgba(255, 255, 255, 0.1) 97.78%
    );
  box-shadow: 4.77952px 7.64723px 60.2219px rgba(0, 165, 234, 0.3),
    inset 0px 0px 62px 2px rgba(255, 255, 255, 0.31);
  border-radius: 17.3958px;
  padding: 40px;
  margin: 10px;
  transition: all 0.5s;
}

.frame-1:hover,
.frame-2:hover,
.frame-3:hover,
.frame-4:hover {
  background: radial-gradient(
        31.95% 71.19% at 22.35% 32.15%,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(
      101.19deg,
      rgba(255, 255, 255, 0.7) 0.88%,
      rgba(255, 255, 255, 0.5) 97.78%
    );
  box-shadow: 0px 4px 57px #00a5ea,
    5.18893px 8.30228px 65.3805px rgba(0, 165, 234, 0.3),
    inset 0px 0px 21px 1px #ffffff;
  border-radius: 18.8859px;
  transform: scale(1.03);
  transition: all 0.5s;
}

.frames span {
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.13px;
  color: var(--blueText);
}

.frames div img {
  width: 170px;
  height: 177px;
}

.features-started {
  text-align: center;
  font-weight: 400;
  font-size: 1.3em;
  color: white;
}

@media (max-width: 1500px) {
  .frames {
    flex-wrap: wrap;
    justify-content: center;
  }
  .title {
    margin-top: 120px;
  }
  .features-started {
    margin-bottom: 80px;
  }
}

@media (max-width: 1000px) {
  .title,
  .subtitle {
    text-align: center;
  }
}

@media (max-width: 769px) {
  .features-container {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .features-container {
    font-size: 12px;
  }
  .title span {
    line-height: 30px;
  }
  .features-content {
    margin: 30px 0 0 0;
  }
}
