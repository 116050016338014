.app-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-image: url("./assets/images/bg-home.png");
  overflow: hidden;
}

.free {
  position: relative !important;
  display: block !important;
}

.page {
  top: 0;
  position: fixed;
  opacity: 0;
  display: none;
}
