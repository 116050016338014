.facts-container {
  width: 100%;
  height: auto;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-family: Raleway, sans-serif;
  font-size: 20px;
}

.facts-title {
  font-size: 2.25em;
  font-weight: 400;
  line-height: 60.93px;
  color: var(--blueText);
  margin-left: 12%;
  margin-top: 150px;
  margin-bottom: 60px;
}

.table-container {
  width: 72%;
  height: 100%;
  padding: 0 14%;
}

.table {
  display: grid;
  grid-template-columns: 0.5fr 2fr 0.5fr;
  column-gap: 40px;
  align-items: center;
  font-size: 1.1em;
  font-weight: 400;
  color: var(--blueText);
}

.fracts-frame-1,
.fracts-frame-2,
.fracts-frame-3 {
  width: 186px;
  height: 186px;
  background: linear-gradient(
    101.19deg,
    rgba(0, 108, 234, 0.8) 0.88%,
    rgba(63, 170, 247, 0.776) 97.78%
  );
  box-shadow: 4.66844px 4.08489px 24.5093px rgba(0, 165, 234, 0.22),
    inset 0px 0px 6.02666px 2.41066px rgba(255, 255, 255, 0.25);
  border-radius: 54.6853px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.post {
  font-size: 0.8em;
}

.post a {
  text-decoration: none;
  color: var(--skyText);
}

.post a > img {
  opacity: 0;
  transform: translateX(-60px);
  transition: all 0.3s;
}
.post a:hover > img {
  opacity: 1;
  transform: translateX(-20px);
  transition: all 0.5s;
}

.post a > span {
  display: inline-block;
  transform: translateX(0);
  transition: all 0.5s;
}

.post a:hover > span {
  display: inline-block;
  transform: translateX(-20px);
  transition: all 0.5s;
}

.post img {
  margin-left: 10px;
}

.column-2 {
  text-align: right;
}

.text {
  margin-bottom: 30px;
}

.facts-buttons img:last-child {
  margin-left: 60px;
}

.facts-buttons a > img {
  transition: all 0.5s;
}

.facts-buttons a:hover > img {
  transform: scale(1.25);
  transition: all 0.5s;
}

@media (max-width: 1280px) {
  .facts-container {
    font-size: 16px;
  }
}

@media (max-width: 1000px) {
  .facts-container {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .table {
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
  }
  .fracts-frame-1,
  .fracts-frame-2,
  .fracts-frame-3 {
    width: 150px;
    height: 150px;
  }

  .right {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 500px) {
  .facts-container {
    font-size: 12px;
    align-items: center;
  }
  .table {
    column-gap: 10px;
    row-gap: 10px;
  }
  .table-container {
    padding: 0 7px;
    width: 98%;
  }
  .facts-title {
    line-height: 30px;
    text-align: center;
  }
  .facts-buttons {
    display: flex;
    flex-direction: column;
  }
  .facts-buttons img:last-child {
    margin: 10px 0 0 0;
  }
  .facts-buttons img {
    width: 120px;
  }
}
