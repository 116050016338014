.contact-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--blueText);
  font-size: 20px;
}

.contact-content {
  width: calc(76% - 80px);
  height: fit-content;
  padding: 60px 40px;
  display: flex;
  background: radial-gradient(
        31.95% 71.19% at 22.35% 32.15%,
        rgba(255, 255, 255, 0.2) 0%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    linear-gradient(
      101.19deg,
      rgba(255, 255, 255, 0.5) 0.88%,
      rgba(255, 255, 255, 0.3) 97.78%
    );
  box-shadow: 4.77952px 7.64723px 60.2219px rgba(0, 165, 234, 0.3),
    inset 0px 0px 20px 2px rgba(255, 255, 255, 0.31);
  border-radius: 20px;
  margin: 100px 0 220px 0;
}

.contact-left {
  width: 100%;
}

.contact-right {
  width: 100%;
  margin-left: 40px;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-title {
  margin-bottom: 10px;
}

.contact-title span:first-child {
  font-family: Raleway, sans-serif;
  font-size: 2.25em;
  font-weight: 400;
  line-height: 60.93px;
}

.contact-title span:nth-child(3) {
  font-family: Raleway, sans-serif;
  font-size: 2.75em;
  font-weight: 700;
  line-height: 74.47px;
  color: var(--skyText);
}

.contact-subtitle {
  font-family: Raleway, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.2em;
  line-height: 135.4%;
  margin-bottom: 80px;
}

.contact-email {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1em;
  line-height: 135.4%;
  display: flex;
  align-items: center;
  margin-bottom: 80px;
}

.contact-email a {
  margin-left: 10px;
  text-decoration: none;
  color: var(--blueText);
}

.contact-social {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1.2em;
  line-height: 135.4%;
}

.social-legend {
  margin-bottom: 10px;
}

.contact-buttons a:not(:first-child) {
  margin-left: 10px;
}

.contact-buttons a > img {
  transition: all 0.3s;
}

.contact-buttons a:hover > img {
  transform: scale(1.25);
  transition: all 0.3s;
}

.contact-right input:not(:last-child) {
  height: 53px;
  background: rgba(63, 204, 247, 0.06);
  backdrop-filter: blur(48.2659px);
  border-radius: 11.4919px;
  border: 1px solid var(--skyText);
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 19px;
  outline: none;
  padding: 0 20px;
}

.error {
  border: 1px solid #982323 !important;
  color: #982323 !important;
}

.contact-right input:focus:not(:last-child) {
  border: 1px solid var(--blueText);
}

.contact-right input:last-child {
  background: linear-gradient(
    101.19deg,
    rgba(0, 165, 234, 0.8) 0.88%,
    rgba(63, 204, 247, 0.8) 97.78%
  );
  box-shadow: 3.81842px 3.34112px 20.0467px rgba(0, 165, 234, 0.22),
    inset 0px 0px 4.92934px 1.97174px rgba(255, 255, 255, 0.25);
  border-radius: 15.1331px;
  border: none;
  color: white;
  height: 63.56px;
  font-family: "Montserrat", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.9em;
  line-height: 22px;
}

.contact-right input:last-child:hover {
  cursor: pointer;
}

.contact-right input:not(:last-child)::placeholder {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 19px;
}

.contact-right textarea {
  background: rgba(63, 204, 247, 0.06);
  backdrop-filter: blur(48.2659px);
  border-radius: 11.4919px;
  border: 1px solid var(--skyText);
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 19px;
  outline: none;
  padding: 20px;
}

.text-error {
  font-size: 0.7em;
  margin: 5px 0 15px 0;
  padding-left: 20px;
  color: #982323;
  height: 20px;
}

.contact-right textarea:focus {
  border: 1px solid var(--blueText);
}

.contact-right textarea::placeholder {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.8em;
  line-height: 19px;
}

.none {
  display: none !important;
}

.contact-right-confirm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 55%;
}

.message-1 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
}

.message-2 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 60px;
}

.message-3 {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 135.4%;
  color: var(--skyText);
}

.message-3:hover {
  cursor: pointer;
}

.check {
  margin-bottom: 40px;
}

@media (max-width: 1200px) {
  .contact-container {
    font-size: 16px;
  }
  .contact-right input:not(:last-child)::placeholder {
    font-size: 1em;
  }
  .contact-right textarea::placeholder {
    font-size: 1em;
  }
}

@media (max-width: 768px) {
  .contact-container {
    font-size: 14px;
  }
  .contact-content {
    flex-direction: column;
    position: relative;
    padding: 20px 20px;
    margin: 100px 0 100px 0;
  }
  .contact-title,
  .contact-subtitle {
    text-align: center;
  }
  .contact-email {
    margin: 540px 0 40px 0;
  }
  .contact-right,
  .contact-right-confirm {
    position: absolute;
    width: 78%;
    top: 220px;
  }
  .contact-right-confirm {
    display: flex;
    flex-direction: column;
    width: 94%;
    top: 320px;
  }
  .message-1,
  .message-2,
  .message-3 {
    text-align: center;
  }
  .contact-right {
    margin-left: 20px;
  }
  .contact-title span:first-child {
    line-height: 30px;
  }
  .contact-title span:last-child {
    line-height: 80px;
  }
  .contact-right input:not(:last-child)::placeholder {
    font-size: 1.2em;
  }
  .contact-right textarea::placeholder {
    font-size: 1.2em;
  }
}

@media (max-width: 500px) {
  .contact-container {
    font-size: 12px;
    margin-top: 100px;
  }
  .title {
    line-height: 30px;
  }
  .contact-content {
    width: 80%;
  }
}
