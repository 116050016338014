.home-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.home-frame {
  width: 76vw;
  height: 68vh;
  background: radial-gradient(
      31.95% 71.19% at 22.35% 32.15%,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      100.42deg,
      rgba(255, 255, 255, 0.7) 0.83%,
      rgba(255, 255, 255, 0.53) 103%
    );
  box-shadow: 4.5625px 31.025px 62.9625px rgba(0, 165, 234, 0.3);
  border-radius: 45.625px;
  display: flex;
  align-items: center;
}

.home-frame-content {
  padding: 8% 8% 8% 10%;
  width: 82%;
  height: 69%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.remember {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 2.25em;
  line-height: 55px;
  color: var(--blueText);
}

.water {
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2.25em;
  line-height: 55px;
  color: #3fccf7;
}

.started {
  display: flex;
  align-items: center;
  position: relative;
}

.circle {
  width: 45px;
  height: 45px;
  border: 1px solid #3fccf7;
  border-radius: 100%;
}

.home-left {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-image {
  width: 36vw;
  min-width: 30vw;
}

.buttons-down {
  display: flex;
}

.buttons-down a:nth-child(2) div {
  margin-left: 60px;
}

@media (max-width: 1500px) {
  .home-image {
    min-width: 575px;
    min-height: 509px;
  }
}

@media (max-width: 1280px) {
  .home-container {
    font-size: 16px;
  }
  .buttons-down div:nth-child(2) {
    margin-left: 20px;
  }
  .home-frame-content {
    align-items: center;
  }
  .home-image {
    min-width: 335px;
    min-height: 300px;
  }
}

@media (max-width: 1000px) {
  .home-container {
    font-size: 14px;
  }
  .buttons-down {
    margin-top: 30px;
  }

  .buttons-down div:nth-child(2) {
    margin-left: 10px;
  }

  .home-left {
    width: 300px;
  }
  .home-frame-content {
    padding: 4%;
    width: 100%;
  }
  .home-left {
    min-width: 50%;
  }
}

@media (max-width: 768px) {
  .home-image {
    position: absolute;
    left: calc(50% - (335px / 2));
    top: calc(46% - (300px / 2));
  }
  .home-left {
    width: 100%;
    text-align: center;
    align-items: center;
  }
  .started {
    margin-top: 200px;
    margin-left: -100px;
  }
  .home-title {
    margin-bottom: 140px;
  }
  .home-frame {
    align-items: stretch;
    position: relative;
  }
  .home-container {
    font-size: 10px;
  }
}

@media (max-width: 480px) {
  .water,
  .remember {
    line-height: 40px;
  }
  .home-frame {
    min-width: 340px;
    height: 100%;
    padding: 20px 0;
    margin: 100px 0 40px 0;
  }
  .home-container {
    font-size: 0.7em;
  }
  .home-image {
    top: calc(50% - (300px / 2));
  }
}
