.phrase-container {
  width: 100%;
  height: 800px;
  background-image: url(../../assets/images/bg-phrase.png);
  position: relative;
  color: var(--blueText);
  display: flex;
  justify-content: center;
  font-size: 20px;
}

.phrase-content {
  width: 45%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.phrase-title {
  text-transform: uppercase;
  font-family: Oswald, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 2.738em;
  line-height: 135.4%;
  margin-bottom: 30px;
}

.phrase-subtitle {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5em;
  line-height: 135.4%;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}

.phrase-subtitle img {
  margin-left: 20px;
}

.a {
  position: absolute;
  top: calc(47% - (479px / 2));
  left: calc((50% - (478px / 2)));
}

@media (max-width: 1200px) {
  .phrase-container {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .phrase-container {
    font-size: 12px;
  }
}

@media (max-width: 500px) {
  .phrase-container {
    font-size: 10px;
  }
}
