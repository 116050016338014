.splash-container {
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.short {
  height: 100vh;
  display: none;
}

.splash-background {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.animation-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.animation {
  position: fixed;
}

@media (max-width: 1200px) {
  .animation {
    width: 120vw;
  }
}

@media (max-width: 800px) {
  .animation {
    width: 160vw;
  }

  @media (max-width: 500px) {
    .animation {
      width: 180vw;
    }
  }
}
