.download-container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--blueText);
  font-size: 20px;
}

.download-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 80%;
  margin: 200px 0 200px 0;
}

.download-title {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  font-size: 2.7913em;
  line-height: 81px;
  margin: 40px 0 70px 0;
}

.download-subtitle {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
  font-size: 1.3937em;
  line-height: 29px;
  margin-bottom: 120px;
  display: flex;
  align-items: center;
}

.download-subtitle img {
  margin-left: 20px;
}

.download-buttons {
  display: flex;
}

.store {
  margin-left: 50px;
}

.download-right {
  margin-left: 60px;
}

@media (max-width: 1200px) {
  .download-container {
    font-size: 16px;
  }
  .download-content {
    align-items: center;
  }
  .download-right {
    margin-left: 30px;
    width: 100%;
  }
  .store {
    margin-left: 20px;
  }
  .download-left {
    width: 100%;
    min-width: 50vw;
  }
  .download-left img {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .download-container {
    font-size: 12px;
    position: relative;
  }
  .download-content {
    margin: 60px 0 0 0;
  }
  .download-title {
    line-height: 40px;
    text-align: center;
  }
  .download-left {
    position: absolute;
    top: calc(100% - 150px);
    left: calc(37vw - 160px);
    min-width: 360px;
  }
  .download-right {
    margin: 0;
  }
  .download-subtitle {
    display: flex;
    justify-content: center;
    margin: 400px 0 20px 0;
  }
  .download-buttons {
    justify-content: center;
  }
}
