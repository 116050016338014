.store-button {
  width: 199px;
  height: 63.56px;
  background: linear-gradient(
    101.19deg,
    rgba(0, 165, 234, 0.8) 0.88%,
    rgba(63, 204, 247, 0.8) 97.78%
  );
  box-shadow: 3.81842px 3.34112px 20.0467px rgba(0, 165, 234, 0.22),
    inset 0px 0px 4.92934px 1.97174px rgba(255, 255, 255, 0.25);
  border-radius: 15.1331px;
  border: 0;
}

.store-button:hover {
  cursor: pointer;
  background: linear-gradient(
    45deg,
    rgba(0, 165, 234, 0.8),
    rgba(63, 204, 247, 0.8)
  );
  box-shadow: 0 0 1rem rgba(63, 204, 247, 0.8);
  transition: all 0.4s;
}

@media (max-width: 1000px) {
  .store-button {
    width: 150px;
    height: 47.9px;
  }
  .store-img {
    width: 75%;
  }
}
